<template>
    <div class="container"  ref="container">
        <div class="home-page-info" >
            <div class="header-box" :class="headerFixed ? 'isFixed' :''">
                <div class="header-left">
                <van-icon name="arrow-left" size="18" :color="headerFixed?'#000':'#fff'" @click="$router.back()" />
                 <avater class="header-avater"  v-if="headerFixed" width="30px" height="30px" :avaterImg="userInfos.avatar || defaultImg" @click.native="handPriviewImg"></avater>
             </div>
                <!-- <van-icon name="ellipsis" size="18" :color="headerFixed?'#000':'#fff'" /> -->
            </div>
        </div>
        <div class="content-box" >
            <div class="conntent-wrap">
                <div class="user-info">
                    <div class="user-info-top">
                        <div class="my-avater">
                            <avater width="60px" height="60px" :avaterImg="userInfos.avatar || defaultImg" @click.native="handPriviewImg"></avater>
                        </div>
                        <div class="eidt-user"  v-if="userInfo.id === userInfos.id"  @click="$router.push('/editUserInfo')">
                            <van-icon name="edit" color="#1989fa" size="14px" />
                            <span class="my-page">编辑资料</span>
                        </div>
                        <van-button v-else  round size="small" type="info" :class="['follow-btn', userInfos.is_follow ? 'active' : '']"
               @click="handleFollows()"> {{ userInfos.is_follow ? '已关注' : '关注'
              }}</van-button>
                          
                    </div>
                    <div class="user-info-center">
                        <span>{{ userInfos.nickname }}</span>
                        <div class="detail-userInfo" v-show="userInfo.id !== userInfos.id"  @click="$router.push({path:'/userInfo',query:{user_id:userInfos.id},})">
                            <span>详细资料</span>
                            <van-icon name="arrow" size="14px" />
                        </div>
                  
                    </div>
                    <div class="user-tab">
                    <van-grid :border="false" column-num="3">
                        <van-grid-item @click="create">
                            <template #default>
                                <div class="count">{{ posts.length }}</div>
                                <div class="text">创作</div>
                            </template>
                        </van-grid-item>
                        <van-grid-item @click="$router.push({path:'/follow',query:{user_id:userInfos.id},})">
                            <template #default>
                                <div class="count">{{userInfos.follow_count }}</div>
                                <div class="text">关注</div>
                            </template>
                        </van-grid-item>
                        <van-grid-item @click="$router.push({path:'/fans',query:{user_id:userInfos.id},})">
                            <template #default>
                                <div class="count">{{userInfos.fans_count }}</div>
                                <div class="text">粉丝</div>
                            </template>
                        </van-grid-item>
                    </van-grid>
                </div>
                </div>
            </div>
            <div class="all">全部</div>
            <div class="post-box" ref="create"> 
                <div class="post-content"  v-if="posts.length !==0">
                    <Aticles ref="aticleRef" :aticleList="posts" :isShowFollowBtn="false" @getPostList="getPostLists" @getMoreaticle="getMoreaticle"></Aticles>
                </div>
                <div class="no-post-content" v-else >
                    <van-empty v-if="userInfo.id === userInfos.id" description="暂无内容,快来发布第一条内容吧">
  <van-button round type="info"  plain class="bottom-button" to="/publish">开始记录</van-button>
</van-empty>
<van-empty  v-else description="ta还没有任何内容">
</van-empty>
                </div>
            </div>
            <van-icon v-show="userInfo.id === userInfos.id" class="quick-action" color="rgb(163 190 247)" name="add" size="40px" @click="$router.push('/publish')"/>
        </div>
              <!-- 出现关注消息提示-->
      <MessagePoint mesageTxt="关注成功" :show="showFollows"></MessagePoint>
      <!-- 出现取消关注消息提示-->
      <MessagePoint mesageTxt="取消关注" :show="cancelFollows"></MessagePoint>
    </div>
</template>

<script>
// 头像
import avater from '@/components/Mobile/avater'
import Aticles from '@/components/Mobile/Aticles'
import MessagePoint from '@/components/Mobile/MessagePoint'
import { relativeTime } from '@/utils/util.js';
import { ImagePreview } from 'vant';
import { mapState } from 'vuex'
export default {
    name: 'HomePage',
    components: {
        avater,
        Aticles,
        MessagePoint,
        [ImagePreview.Component.name]: ImagePreview.Component,
        
    },
    data() {
        return {
            // 默认头像
            defaultImg: require('@/assets/images/userLogo.jpg'),
            //头像数组
            images:[],
            limit:10,
            // 搜索关键词
             keyword: "",
             //帖子列表
             posts:[],
             //是否吸顶
             headerFixed: false,
             //用户信息
             userInfos:{},
             showFollows: false, //出现关注信息提示
             cancelFollows: false, //出现取消关注信息提示
        };
    },
    computed: {
        ...mapState(['userInfo'])
    },
    activated() {
        this.getPostLists();
        this.getUserInfoById();
    },
    mounted() {
   this.headerFixed= false;
   window.addEventListener('scroll', this.handleScroll,true);
},

destroyed () {
  window.removeEventListener('scroll', this.handleScroll)
},


    methods: {
        //预览头像
        handPriviewImg()
        {
            //将对象变成数组
            this.images=[{...this.userInfos}];
            ImagePreview({
        images: this.images.map(item=>item.avatar),
        loop: false,
        startPosition: 0
      });
        },
        // 获取个人所发帖的信息
        // 获取帖子列表
    async getPostLists() {
      // 优化bug，防止出现首次多加载一次的情况
      try {
        let { data: res } = await this.api
          .postFormAPISM(
            {
              limit:this.limit,
              keywords: this.keyword || "",
              start_time: "",
              user_id:  Number(this.$route.query.user_id) || this.userInfo.id 
            },
            "/article/getpostarticlelist"
          )
           console.log(res.data);
   
        this.posts = res.data.article_info_list;
        // 转化时间格式
        // this.posts.forEach((item) => {
        //   item.create_time = relativeTime(item.create_time);
        // });
      } catch (error) {
        console.log(error);
      }
    },
    // 滚动吸顶
    handleScroll () {
     //计算滚动条位置
	 var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
	 //计算绑定div位置
	//  var offsetTop = this.$refs.container.getBoundingClientRect().top;
     this.headerFixed = scrollTop>80?true:false;
},
     // 获取用户信息
   async  getUserInfoById()
     {
      
        try {
        let { data: res } = await this.api
          .postFormAPISM(
            {
              user_id:Number(this.$route.query.user_id) ||  this.userInfo.id
            },
            "/user/getuserinfobyid"
          )
          let { fans_count, follow_count, user,user_follow_ship } = res.data;
          let { is_follow } = user_follow_ship
          let userInfo = {
                        fans_count: fans_count || 0,
                        follow_count: follow_count || 0,
                        is_follow,
                        ...user,
                    }
             this.userInfos={...userInfo};
      } catch (error) {
        console.log(error);
      }
     },
        // 点击关注
    async handleFollows() {
        //  想要关注的用户id
        const target_user_id = this.userInfos.id;
        let is_follow = this.userInfos.is_follow;
        // 发起关注请求
        await this.api.
          postFormAPISM(
            {
              target_user_id,
              is_follow: !is_follow,
              remark_name: ""
            },
            "/user/follow"
          );
        if (is_follow) {
          // 弹出取消关注提示
          this.cancelFollows = true;
          setTimeout(() => {
            this.cancelFollows = false;
          }, 500);
        }
        else {
          // 弹出关注提示
          this.showFollows = true;
          setTimeout(() => {
            this.showFollows = false;
          }, 500);
        }
        // 切换是否关注
        this.userInfos.is_follow = !this.userInfos.is_follow;
    
    },
    // 锚点，去创作位置
    create()
    {
      const box = this.$refs.create;
      box.scrollIntoView({
        behavior: "smooth",
      });
    },
    // 加载更多帖子
  async  getMoreaticle()
    {
        // 如已经加载完成就不用继续下去
        if(this.$refs.aticleRef.finished) return;
      try {
        let { data: res } = await this.api
          .postFormAPISM(
            {
              start_time:this.posts[this.posts.length - 1].create_time,
              user_id:  Number(this.$route.query.user_id) || this.userInfo.id 
            },
            "/article/getpostarticlelist"
          )
   
        let arrs = res.data.article_info_list;
                this.posts = [...this.posts, ...arrs];
                // 把loading设置为false
                // this.$refs.aticleRef.loading = false;
                // 判断是否所有的数据全部加载完成，如果是：finished设为true
                if (arrs.length === 0) {
                    // 说明取不到数据，加载完成，finish设置为true
                    this.$refs.aticleRef.finished = true;
                }
            } catch (error) {
                console.log(error);
                this.$refs.aticleRef.finished = false;
            }
    }

    },
};
</script>

<style lang="less" scoped>
@import './index.less';
</style>